
@font-face {
  font-family: 'Overlock-bold';
  src: local('Overlock-local'), url(./fonts/Overlock-Bold.ttf) format('truetype');}

  @font-face {
    font-family: 'Overlock-regular';
    src: local('Overlock-local'), url(./fonts/Overlock-Regular.ttf) format('truetype');}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /* animation: App-logo-spin infinite 20s linear; */
        animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.brand-img {
  z-Index: 2;
  opacity: .9;
  max-width: 40%;
  max-height: 40%;
  text-align: center;
  position: absolute; 
  margin-bottom: 8%;
}

.slogan {
  margin-top: 15% !important;
  text-align: center;
  position: absolute; 
  color: white;
  z-index: 2;
  font-family: Overlock-bold, sans-serif;
  font-size: 2vw;
  color: 0xFFFF;
}

.join-us {
  margin-bottom: 0% !important;
  z-index: 6 !important;
  text-align: center;
  position: absolute;
}

.bkgrnd {
  width: 100%;
  z-Index: 1;
  top: 0;
  left: 0;
}

.parent-bg {
  justify-content: center;
  position: relative;
  display: flex;
  align-items: center;
  z-index: 3;
}

.button-bar{
  margin-top: 0px;
  /* position: absolute;  */
  z-index: 4;
  color: #00EAFA;
}

.btn-custom{
  text-align: center;
  position: absolute; 
  align-self: top;
  z-index: 4;
  background-color: #00EAFA;
  color: white;
}

.btn-circle span {
  margin: 0 -2rem;
}

.btn-circle {
  font-family: Overlock-bold, sans-serif;
  /* width: 80px; */
  /* height: 30px; */
  width: 10vw;
  /* height: 8vh; */
  text-align: center;
  /* padding: 6px 0; */
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
  background-color: #00EAFA;
  color: white;
  border-color: #00EAFA;
}

.btn-circle span {
  font-size: 1.5vw;
}

.nav {
  list-style-type: none;
  text-align: center;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: inherit;
  /* float: top; */
  padding-bottom: 50%;
}


.nav li {
  display: inline-block;
  font-size: 20px;
  padding: 20px;
}

.nav a {
  margin-top: 0px;
  z-index: 4;
  color: #00EAFA; 
}

.banners img {
  display: block;
}

.square-features {
  /* border: solid 2px; */
  border-color: rgb(203, 199, 200, 0.8);
  width: 45%;
  height: 70%;
  /* background: #CBC7C8; */
  position: absolute; 
  z-index: 4;
  /* opacity: .8; */
  background-color: rgb(203, 199, 200, 0.8);
}

.features-content {
  position: absolute;
  width: 100%;
  height: 100%;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  /* padding: 10px; */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  grid-column-gap: 1vw;
  padding: 1vw;
}

.grid-item {
  /* padding: 20px; */
  font-size: 2vw;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid-item p {
  text-align: center;
  color: black;
  font-size: 1.1vw;
  font-family: Overlock-regular, sans-serif;
  font-weight: lighter;
  /* max-width: 140px; */
}

.grid-item h3 {
  text-align: center;
  color: white;
  font-family: Overlock-bold, sans-serif;
  font-size: 3vw;
  z-index: 5;
}

.grid-container-features {
  display: grid;
  grid-template-columns: auto;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;


}

.grid-item-blue {
  background-color: rgb(116, 223, 232, .8);  
  font-size: 1.5vw;
  text-align: center;
  display: grid;
  align-items: center;
  justify-content: center;
  font-family: 'Overlock-regular';
  font-weight: lighter;
  z-index: 5;
  /* margin-top: 3vh; */
  /* margin-bottom: 3vh; */
}


.grid-item-gray {
  /* padding: 20px; */
  font-size: 1.5vw;
  text-align: center;
  display: grid;
  align-items: center;
  justify-content: center;
  font-family: 'Overlock-regular';
  font-weight: lighter;
  margin-top: 3vh;
  z-index: 5;
  /* margin-bottom: 3vh; */
  /* height:50% */
  
}
.grid-container-features h3 {
  font-family: 'Overlock-bold';
  line-height: 0px;
}

.grid-container-features p {
  max-width: 23vw;
}


.about {
  text-align: center;
  margin-left: 3vw;
  position: absolute;
  z-index: 4;
  font-family: Overlock-bold, sans-serif;
  max-height: 40vw;
  /* line-height: 4vh; */
  /* overflow: scroll; */
  /* padding-right: 20px */
}

.about p {
  font-family: Overlock-regular, sans-serif;
  /* font-weight: bold; */
  /* width: 100%; */
  font-size: 2vw;
}

.about h1 {
  font-family: Overlock-regular, sans-serif;
  font-weight: bold;
  font-size: 3vw;
}

.footer {
  background-color: rgb(203, 199, 200);
  display: flex;
  text-align: center;
  justify-content: center;
  line-height: 0;
  font-size: 2vh;
}


.grid-container-memberships {
  display: grid;
  grid-template-columns: auto;
  /* padding: 10px; */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.grid-container-memberships h3 {
  font-family: 'Overlock-bold';
  /* line-height: 0px; */
}

.grid-container-memberships p {
  max-width: 23vw;
}


.grid-item-features-gray {
  /* padding: 20px; */
  font-size: 2vw;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid-item-features-gray p {
  text-align: center;
  color: black;
  font-size: 1vw;
  font-weight: lighter;

  font-family: Overlock-regular, sans-serif;
  /* max-width: 140px; */
}

.grid-item-features-gray h3 {
  text-align: center;
  color: white;
  font-family: Overlock-bold, sans-serif;
  font-size: 2.5vw;
  z-index: 5;
}

.grid-item-features-blue {
  /* padding: 20px; */
  font-size: 2vw;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(116, 223, 232, .8);  
}

.cancel {
  text-decoration:line-through;
  color: red;
}

.grid-item-features-blue p {
  text-align: center;
  color: black;
  font-size: 1vw;
  font-weight: lighter;
  font-family: Overlock-regular, sans-serif;
  /* max-width: 140px; */
}

.grid-item-features-blue h3 {
  text-align: center;
  color: white;
  font-family: Overlock-bold, sans-serif;
  font-size: 2.5vw;
  z-index: 5;
}